import React from 'react';

import './style.css';

export default function Board({width,height}) {
    return (
        <div style={{width, height}} className="board-container">

        </div>
    )
}